<template>
  <div id="header_wrapper">
    <div id="header">
      <h1>
        Mel Edelman -
        <span style="color: #818273"> Artist | Photographer </span>
      </h1>

      <div id="navigation">
        <ul>
          <li v-for="link in links" :key="link.text">
            <router-link :to="{ name: link.link }" exact>
              {{ link.text }}
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        {
          text: "Home",
          link: "index",
        },
        {
          text: "Artist Books",
          link: "books",
        },
        {
          text: "Photography",
          link: "photography",
        },
        {
          text: "Video & Film",
          link: "film",
        },
        {
          text: "Installation",
          link: "installation",
        },
        {
          text: "Conceptual Work",
          link: "conceptual",
        },
        {
          text: "Collages",
          link: "misc",
        },
        {
          text: "About",
          link: "bio",
        },
        {
          text: "Contact",
          link: "contact",
        },
      ],
    };
  },
};
</script>

<style scoped>
ul {
  margin: auto;
  width: 90%;
}
</style>
