import Vue from "vue";
import VueRouter from "vue-router";
import Index from "@/views/Index.vue";

Vue.use(VueRouter);

function returnGalleryProps(route) {
  if (route.params.gallery === "antarctica") {
    return {
      pageName: "Photography",
      hasThumbnails: true,
      galleryName: "Antarctica",
      folderName: "photography/antarctica",
    };
  }

  if (route.params.gallery === "armor") {
    return {
      pageName: "Conceptual",
      hasThumbnails: true,
      galleryName: "Body Armor Photos",
      folderName: "conceptual/armor",
    };
  }

  if (route.params.gallery === "conceptual") {
    return [
      {
        hasThumbnails: false,
        galleryName: "Serious Bldg. Climber's Handbook (1981)",
        folderName: "conceptual/climb",
      },
      {
        hasThumbnails: false,
        galleryName: "Autobiographical Work (1989-1995)",
        folderName: "conceptual/bio",
      },
    ];
  }

  if (route.params.gallery === "deutschland") {
    return {
      pageName: "Collages",
      hasThumbnails: true,
      galleryName: "Deutschland, 1941",
      folderName: "misc/deutschland",
    };
  }

  if (route.params.gallery === "faceless") {
    return {
      pageName: "Installation",
      hasThumbnails: false,
      galleryName: "Nameless & Faceless",
      folderName: "installation/faceless",
    };
  }

  if (route.params.gallery === "fire") {
    return {
      pageName: "Photography",
      hasThumbnails: true,
      galleryName: "Burning Books",
      folderName: "photography/fire",
    };
  }

  if (route.params.gallery === "geometry") {
    return {
      pageName: "Photography",
      hasThumbnails: true,
      galleryName: "Geometry of Photography (2018)",
      folderName: "photography/geometry",
    };
  }

  if (route.params.gallery === "iceland") {
    return {
      pageName: "Photography",
      hasThumbnails: true,
      galleryName: "Iceland (2019)",
      folderName: "photography/iceland",
    };
  }

  if (route.params.gallery === "india") {
    return {
      pageName: "Photography",
      hasThumbnails: true,
      galleryName: "India",
      folderName: "photography/india",
    };
  }

  if (route.params.gallery === "littlepeople") {
    return {
      pageName: "Photography",
      hasThumbnails: true,
      galleryName: "Little People",
      folderName: "photography/littlepeople",
    };
  }

  if (route.params.gallery === "mixed") {
    return {
      pageName: "Collages",
      hasThumbnails: true,
      galleryName: "Mixed Media Portraits (1997)",
      folderName: "misc/mixed",
    };
  }

  if (route.params.gallery === "ransom") {
    return {
      pageName: "Collages",
      hasThumbnails: true,
      galleryName: "Ransom Notes (2018)",
      folderName: "misc/ransom",
    };
  }

  if (route.params.gallery === "smells") {
    return {
      pageName: "Artist Books",
      hasThumbnails: false,
      galleryName: "Smells Like (2016)",
      folderName: "books/smells",
    };
  }

  if (route.params.gallery === "travels") {
    return {
      pageName: "Conceptual",
      hasThumbnails: true,
      galleryName: "American Travels",
      folderName: "conceptual/travels",
    };
  }

  if (route.params.gallery === "unshuttered") {
    return {
      pageName: "Collages",
      hasThumbnails: true,
      galleryName: "The Unshuttered Window Invites Our Gaze",
      folderName: "misc/unshuttered",
    };
  }
}

const routes = [
  {
    path: "/books",
    name: "books",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Books.vue"),
  },
  {
    path: "/books/:gallery",
    name: "books-gallery",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/PhotoGalleryPage.vue"
      ),
    props: (route) => ({ galleryProps: returnGalleryProps(route) }),
  },
  {
    path: "/photography",
    name: "photography",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Photography.vue"),
  },
  {
    path: "/photography/:gallery",
    name: "gallery",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/PhotoGalleryPage.vue"
      ),
    props: (route) => ({ galleryProps: returnGalleryProps(route) }),
  },
  {
    path: "/film",
    name: "film",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Film.vue"),
  },
  {
    path: "/installation",
    name: "installation",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Installation.vue"),
  },
  {
    path: "/installation/:gallery",
    name: "installations",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/PhotoGalleryPage.vue"
      ),
    props: (route) => ({ galleryProps: returnGalleryProps(route) }),
  },
  {
    path: "/conceptual",
    name: "conceptual",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Conceptual.vue"),
    props: (route) => ({ galleryProps: returnGalleryProps(route) }),
  },
  {
    path: "/misc",
    name: "misc",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Misc.vue"),
  },
  {
    path: "/misc/:gallery",
    name: "works",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "@/components/PhotoGalleryPage.vue"
      ),
    props: (route) => ({ galleryProps: returnGalleryProps(route) }),
  },
  {
    path: "/bio",
    name: "bio",
    component: () => import(/* webpackChunkName: "about" */ "@/views/Bio.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Contact.vue"),
  },
  {
    path: "/",
    name: "index",
    component: Index,
  },
  {
    path: "*",
    redirect: { name: "index" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
