<template>
  <div id="page">
    <div id="container">
      <div class="text-center">
        <img :src="landing" width="800px" />
      </div>
    </div>
  </div>
</template>

<script>
// import home from "@/static/images/home.jpg";
import wasp from "@/static/images/wasp.gif";

export default {
  data() {
    return {
      landing: wasp,
    };
  },
};
</script>
