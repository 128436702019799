import Vue from "vue";

import VueSilentbox from "vue-silentbox";

import App from "./App.vue";

import router from "./router";

import "@/static/css/theme.css";
import "@/static/css/main.css";

Vue.config.productionTip = false;
Vue.use(VueSilentbox);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
